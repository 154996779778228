@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  /* background-color */
  --background-color-1: linear-gradient(145deg, #1e2024, #23272b);
  --background-color-2: #212428;

  /* box-shadow */
  --shadow-1: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  --shadow-2: inset 21px 21px 19px #181a1d, inset -21px -21px 19px #202225;
  --inner-shadow: 1px 4px 2px -3px rgba(0, 0, 0, 0.7) inset,
    -1px -3px 3px -2px rgba(255, 255, 255, 0.2) inset;

  --shadow-white-3: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
  --shadow-gray: 5px 5px 15px #d1d9e6, -5px -5px 15px rgba(255, 255, 255, 0.2);

  /* Gradient */
  --gradient-box-w: linear-gradient(145deg, #e2e8ec, #ffffff);
  --gradient-red-hover: linear-gradient(145deg, #02b1f3, #0b91d3);
  --gradient-box-primary: linear-gradient(145deg, #02b1f3, #02b1f3);
}

.letterWordSpacing {
  letter-spacing: 3px;
  word-spacing: 2px;
}

@layer utilities {
  .animate-fade-in {
    animation: fadeIn 1s ease-in;
  }

  .animate-slide-up {
    animation: slideUp 0.8s ease-out;
  }
}
